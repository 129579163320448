import { devices } from '@/utils/devices'

export default {
	name: 'global',
	store() {
		return {
			isMinicartVisible: false,
			isSearchVisible: false,
			device: devices[0].name as 'mobile' | 'tablet' | 'desktop',
			viewport: { vw: window.innerWidth, vh: window.innerHeight },
			prefersReducedMotion: false,
			cart: undefined as any,
			hasBodyLock: false,
			bodyLocksCount: 0,
			savedScrollY: window.scrollY,
			init() {
				this.initLiquidAJaxCart()

				this.setDevice()
				window.addEventListener('resize', this.setDevice.bind(this), { passive: true })

				this.initPrefersReducedMotion()
			},
			initLiquidAJaxCart() {
				document.addEventListener('liquid-ajax-cart:request-end', (event: Event & any) => {
					const { requestState, cart } = event.detail

					if (requestState.requestType === 'add') {
						if (requestState.responseData?.ok) {
							this.isMinicartVisible = true
						}
					}

					this.cart = cart
				})
			},
			setDevice() {
				this.viewport.vw = window.innerWidth
				this.viewport.vh = window.innerHeight

				const currentDevice = devices.find(d => d.breakpoint < this.viewport.vw)?.name || devices[0].name
				this.device = currentDevice
			},
			bodyScrollLock(shouldLock) {
				if (shouldLock) {
					if (!this.hasBodyLock) {
						this.savedScrollY = window._lenis.scroll
						window._lenis.destroy();

						document.body.style.position = "fixed"
						document.body.style.top = `-${this.savedScrollY}px`;
					}
					this.bodyLocksCount += 1
					this.hasBodyLock = shouldLock
				} else {
					this.bodyLocksCount -= 1

					if (this.hasBodyLock && this.bodyLocksCount === 0) {
						document.body.style.removeProperty('position')
						document.body.style.removeProperty('top')

						window._getLenis()
						window._lenis.scrollTo(this.savedScrollY, { immediate: true, force: true })

						this.hasBodyLock = shouldLock
					}
				}
			},
			initPrefersReducedMotion() {
				const mql = window.matchMedia("(prefers-reduced-motion: reduce)");
				mql.addEventListener("change", this.handlePrefersReducedMotionChange.bind(this), { passive: true });
				this.prefersReducedMotion = mql.matches;
			},
			handlePrefersReducedMotionChange(event: MediaQueryListEvent) {
				this.prefersReducedMotion = event.matches
			}
		}
	}
}
