// Base Source: https://codepen.io/elliott-w/pen/abWqeOL

export default {
    name: 'marquee',
    component(props) {
        const { speed = 1 } = props

        return {
            isActive: false,
            init() {
                this.originalElement = this.$el.cloneNode(true)
                const originalWidth = this.$el.scrollWidth

                this.$el.style.setProperty('--marquee-width', `${originalWidth}px`)
                this.$el.style.setProperty('--marquee-time', `${((1 / speed) * originalWidth) / 100}s`)

                this.resize()

                this.$useWindowResize(this.resize.bind(this))
            },

            resize() {
                this.$el.innerHTML = this.originalElement.innerHTML

                // activate only if there is enough content
                this.isActive = Boolean(this.$el.clientWidth >= this.$el.parentNode.clientWidth)

                if (this.isActive) {
                    // Duplicate the content in the marquee
                    let i = 0
                    while (i < this.originalElement.childElementCount) {
                        this.$el.appendChild(this.originalElement.children[i].cloneNode(true))
                        i += 1
                    }
                }
            }
        }
    }
}
