export default {
	name: 'header',
	component() {
		return {
			isMobileNavOpen: false,
			hasScrolled: Boolean(window.scrollY >= window.innerHeight),
			init() {
				// add on window since _lenis.on will not be persisted after a body scroll lock because we destroy the instance
				window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
			},
			handleScroll(e) {
				if (!this.$store.global.hasBodyLock) {
					this.hasScrolled = Boolean(window._lenis.animatedScroll >= this.$store.global.viewport.vh)
				}
			},
			handleBurgerClick() {
				this.isMobileNavOpen = !this.isMobileNavOpen
			},
			closeMobileNav() {
				this.isMobileNavOpen = false
			},
		}
	}
}
